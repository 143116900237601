<script>
  export default {
    name : 'FriendRequest',
    mounted() {
      if (this.$route.params.chatId && this.$route.params.userType) {
        localStorage.friendChatId = this.$route.params.chatId;
        localStorage.friendUserType = this.$route.params.userType;

        if (this.$store.getters['auth/isLoggedIn'] &&
          this.$store.getters['user/user'].chat_id !==
          this.$route.params.chatId)
          this.$router.push('/download');
        else
          this.$router.push('/login');
      }
    },
  }
</script>